import React, {useState, useEffect} from 'react';
import {Navbar, Row, Col} from 'react-bootstrap'
import SearchControl from './Shared/SearchControl'
import {NavLink} from 'react-router-dom'

const Footer = ({etat, connected, onSearch}) => {
    const [time, Time] = useState("00:00:00");

    useEffect(() => {
        setTimeout(() => {
            Time((new Date()).toLocaleTimeString())
        }, 1000);
    },[time]);

    return (
        <Row className="Footer">
            <Col sm={4}>
            <i className="FooterEtat" key="FooterEtat">{etat}</i>
            </Col>

            <Col sm={3}>
                { connected ?
                <SearchControl onSearch={onSearch} />
                : 
                <Navbar>
                <NavLink className="d-inline p-3 navbar-custom" to="/signup" >
                    <i className="fa fa-edit"> Register</i></NavLink>  
            </Navbar>    
                }
            </Col>
            <Col  sm={1}>
            <a target="_blank" rel="noopener noreferrer" href={"https://telegram.me/akademymabot"}><img src="https://akademy.ma/img/s-telegram.png" alt="telegram" /></a>
            </Col>
            <Col  sm={3}>
            <i><b>@ Akademy 2021 - </b>{time}</i>
            </Col>
        </Row>
    )
}

export default Footer;

/*
<script>
function showTime() {
    var d = new Date();
    document.getElementById("clock").innerHTML = d.toLocaleTimeString();
}
setInterval(showTime, 1000);
</script>
 
<p>Time: <span id="clock"></span></p>
*/

