import React, {useEffect, useState} from 'react'
import {Row, Col, Button} from 'react-bootstrap'
import SignModal from './Sign/SignModal'

const Header = ({onSearch, onEtat, route, layout, logo, connected, onConnect, tab, onTab}) => {
    const app = "Akademy"
    const [showing, Showing] = useState(false)

    useEffect(() => {
        let store = localStorage.getItem(app)
        //console.log('localStorage',store)
        if(store) {
            let infos = JSON.parse(store)
            
            onConnect(infos?.connected)
        }
    },[])

    const Logout = (e) => {
        localStorage.removeItem(app)
        onConnect(false)
        window.location.reload()
    }

    const Login = (e) => {
        Showing(true)
    }

    const onClick = (e) => {

        let name = e?.target?.id
        //console.log(e.target); alert(name)
        if(name) {
            onTab(name)
        }
    }

    const bstyle = { width: '6rem', display: 'block', margin:'0.2rem', fontSize:'1.2rem' }  

    return (
        
        <Row className="Header" id="Header">
            <Col sm={2}>
                <a href="/">
                    <img className="Logo" src={logo} alt="Home" />
                </a>
            </Col>

            <Col sm={8} >
                <ul className="nav">
                    <li id={"Part"} className={tab === "Part" ? "active" : ""} onClick={onClick}>Part</li>
                    <li id={"Read"} className={tab === "Read" ? "active" : ""} onClick={onClick}>Read</li>
                    <li id={"Note"} className={tab === "Note" ? "active" : ""} onClick={onClick}>Note</li>
                </ul>                
            </Col>            
            <Col sm={2}>
                { connected ?
                    <Button style={bstyle} variant="outline-secondary" onClick={Logout}>
                        <i className="fa fa-sign-out"> Exit</i>
                    </Button>
                :
                    <Button style={bstyle} variant="outline-primary" onClick={Login}>
                        <i className="fa fa-sign-in"> Login</i>
                    </Button>
                }
            </Col>   

<SignModal 
    app={app}
    show={showing}
    logo={logo}
    onHide={()=>{Showing(false)}}
    onEtat={onEtat}
/>                              
        </Row>
    )
}

export default Header;