import React, {useState, useEffect, useCallback, useRef} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import './App.css'

import Home from './components/Home'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  const logo = 'images/tilaawa.png'
  const route = useRef("/")
  const [search, Search]  = useState("")
  const [etat, Etat]  = useState("")
  const [tab, Tab] = useState("Read")
  const [connected, Connected] = useState(false)
  const [layout, Layout] = useState(window.matchMedia('(orientation:landscape)').matches)

  const onResize = useCallback(event => {
    let value=window.matchMedia('(orientation:landscape)').matches    
    Layout(value)
  }, [])

  useEffect(() => {
          window.addEventListener("resize", onResize);
          return () => {
              window.removeEventListener("resize", onResize);
          };
  }, [onResize, layout])

  return (
<div className='App'>

<BrowserRouter>

  <Header route={route} layout={layout} logo={logo} onEtat={Etat} connected={connected} onConnect={Connected} tab={tab} onTab={Tab} />

  <Routes>
  <Route path="/" element={<Home tab={tab} search={search} onTab={Tab} onEtat={Etat} connected={connected} />} />
  </Routes>

  <Footer etat={etat} connected={connected} onSearch={Search} />

</BrowserRouter>

</div>

)

}

export default App;


/*
 *   <Navigate to={route.current} />
 * <Route path='/' component={() => <Home search={search} onEtat={Etat} />} exact />
 */
