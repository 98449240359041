import React, {useState} from 'react'
import {ToNotify} from './Shared/ViewControl'
import Reading from './Reading/Reading'

const Home = ({tab, search, connected, onTab, onEtat}) => {
    const [message, Message] = useState('')
    const [page, Page] = useState(1)

    const onPage = (p) => {
        Page(p)
        onTab("Read")
    }
        return (
            <div className="Home">
            {   connected   ?
                <Reading tab={tab} page={page} search={search} onPage={onPage} onEtat={onEtat} />
            :
                <div style={{margin:'1rem'}}>
                    <h1>مرحبًا، الرجاء الدخول أو التسجيل</h1>
                    <h2>Welcome, please login or sign up</h2>
                </div>
            }

<ToNotify 
        message={message}
        onClose={()=>{Message('')}}
        onEtat={onEtat}
    />    
            </div>
        )
}

export default Home
