import axios from 'axios';

const Api = axios.create({

  baseURL: `https://akademy.digitalservices.ma`, 
  //baseURL: `https://localhost:44371`,  

  headers : {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('Akademy'))?.token}`,       
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})


async function Fetch(request, item=null, crud=2, Load=null, Message=null, Items=null) {
  //  alert(Api.defaults.baseURL+" "+request+" "+crud+" "+Api.defaults.headers.Authorization)

  const methods="1234"
  if(!methods.includes(crud)) 
    throw new Error(`Fetch method ${crud} invalid ?`) 

  const method = (crud===1 ? 'POST' : (crud===3 ? 'PUT' : (crud === 4 ? 'DELETE' : 'GET')))

  let response = await fetch(Api.defaults.baseURL+"/"+request,{
      method: method,
      headers: Api.defaults.headers,
      body: item && JSON.stringify(item)
  })

  if(response.ok){
          
    if(crud!==2) {
      Message && Message(`Api.Fetch ${response.status} !`)
      Load && Load(false)
    }
    else {
      let data = await response.json()
      //console.log('Fetch ', request, data)
      Items && Items(data)  // 2 hours :) 
      Load && Load(true)
    }
  }
  else {
    Message && Message(`Api.Fetch ${response.status} ?`)
    console.error(response)
  }
}

async function Post(controller, item, Message=null) {
    //console.log('Post', controller, item)  //alert(controller)
  let response = await Api.post(controller,item)
  if(response.ok){          
      Message && Message(`Api.Post ${response.status} !`) 
  }
  else {
    Message && Message(`Api.Post ${response.status} ?`)
    console.error(response)
  }
}

const Get = (request, Items, Load, Message=null) => {
  //alert(request)
  Items([])
  Api.get(request)
  .then(response => {
      //console.log('Api.Get', request, response.data)
      Items(response.data)
      Load && Load(true)
      Message && Message(response)
  })
  .catch(error => {
      Message && Message(error)
      console.error(error)
  })
}

export default Api
export {Fetch, Get, Post}
