import React,{useEffect, useState} from 'react'
import {Button, Col} from 'react-bootstrap'
import './Reading.css'

const ReadControl = ({path,part,page, onPrev,onNext, onPart, onPage}) => {
    const [play, Play] = useState(false)

    //let safha=parseInt(page.substr(page.length-7,3)) || 0
    let istyle={display: 'flex', justifyContent: 'center'}
    let tstyle = { position:'absolute', width: '10%', height: '5%', fontWeight:'bold', color:'darkred'}

    let safha=path+(page||0).toString().padStart(3,'0')+'.jpg'
    let tilaawa = safha.replace(".jpg",".mp3")    
    var audio = document.getElementById("tilaawa");

    const onPlay = () => {
        if(!play) {
            audio.play()
            audio.style.visibility='visible'
        }
        else {
            audio.pause()
            audio.style.visibility='hidden'
        }
        Play(!play)
    }
    //audio.stopAll();
    //alert(safha+" "+tilaawa)

    useEffect(()=>{
        //console.log('ReadControl.page', page)
        if(page && onPage)
            onPage(page)

        if(play && onPlay) {
            onPlay()
        }
    },[page])

    return (
        <div className="page-center">
        {
            safha ?? safha.length>0 
        ?
        <>

        <img className="page-fit page-fit-center" id="safha" alt="القرآن الكريم" 
        src={safha} 
        />
        
        
        <Button className="mr-2" variant="outline-warning" 
        style={{ position:'absolute', width: '5%', height: '10%', top:'45%', left:'1%' }}
            onClick={onNext}
            ><i className="fa fa-arrow-left" style={{display: 'flex', justifyContent: 'center'}}></i></Button>

        <Button className="mr-2" variant="outline-warning" 
        style={{ position:'absolute', width: '5%', height: '10%', top:'45%', left:'94%' }}
            onClick={onPrev}
            ><i className="fa fa-arrow-right" style={{display: 'flex', justifyContent: 'center'}}></i></Button>

<audio id="tilaawa" controls="controls" autobuffer="autobuffer" 
style={{position:'absolute',width:'90%', height:'5%', bottom:0, left:'5%', visibility:'hidden', opacity:'0.7'}} 
src={tilaawa} />

<Button className="mr-2" variant={play?"outline-danger":"outline-success"}  
        style={{ position:'absolute', width: '10%', height: '5%', bottom:0, left:'45%', opacity:'0.7' }}
            onClick={onPlay}
            ><i className={play?"fa fa-pause":"fa fa-play"} style={istyle}></i></Button>

    <div style={{ ...tstyle,  top:'0', left:'0', color:'goldenrod' }}>
        <p>{part.id}</p>
    </div>

    <div style={{ ...tstyle, top:'97%', left:'90%' }}>
        <p>{page}</p>
    </div>

</>
        :   null
        }
        </div>
    )
}


export default ReadControl

/**
 * {play?"fa fa-pause":"fa fa-play"}
 */