import React, { useState, useEffect } from 'react'
import {Row,Col,Form,Button} from 'react-bootstrap'
import './Reading.css'

const Parts = ({path, part, page, onPart, onPage}) => {
        const [selected, Select] = useState(part)
        
        useEffect(()=>{
            onPart(selected)
        },[selected])

        const onChange = (part) => {
            Select(part)
        }

    return (
    <Col className='pages'>
            <PartControl part={selected} name='parts' page={page} onPart={onChange} />

            <Part part={selected} path={path} page={page} onPage={onPage} />
    </Col>
    )
}

const PartControl = ({part, name, page, onPart, label='جزء'}) => {
    const [items, Items] = useState([])
    const [loaded,Loaded] = useState(false)

    useEffect(()=>{
        //  console.log('moshaf',moshaf)
        fetch(`${moshaf.api}joze/ajzaae`, {
            headers: {
                Authorization: `Bearer ${moshaf.token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            Items(data)
            //console.log('ajzaae',items)
            if(data.length===moshaf.parts)
                Loaded(true)
        })
        .catch(function (error) {
            console.log('error',error)
        })
    },[loaded])

    const onChange = (e) =>{            
        e.preventDefault();
        let id=e.target.value||1
        //console.log('part and page selected', id, items[id-1])
        let part = {id:id, page: items[id-1].Safha, pages: items[id-1].Safahaat}
        onPart(part)
    }
 
    return (
        loaded ? 
        <Row className='parts' style={{height:'50px'}}>          
            <Form.Control className='parts-select parts' as="select" style={{overflow: 'auto'}}
                name={name} defaultValue={part.id} onChange={onChange} >
                {
                    items.map(item => (
                        <option key={item.Id} value={item.Id} style={{direction:'rtl', width:'auto'}} alt={`${item.Safha},${item.Safahaat}`}> 
                            {`${item.Id}: ${item.Soora}: ${item.Aaya.substring(0,60)}`}
                        </option>
                    ))
                }
            </Form.Control>
            <Form.Label style={{ width:'30px', display: 'flex', justifyContent: 'flex-end', verticalAlign:'middle' }}>{label}
            </Form.Label>
        </Row>

: <div>{name} is loading...</div>

)}

const Part = ({part, path, page, onPage}) => {

    const [selected,Select] = useState(page)    
    const [items, Items] = useState([])

    useEffect(()=>{
        var pages=[]
        for(let p=0; p<part.pages; p++){
            let page=part.page+p
            let item = {
                part: part.id,
                page: page,
                path: path + page.toString().padStart(3,'0') + '.jpg'
            }
            pages.push(item)
        }
        Items(pages)  // after hours !
        //console.log('load pages:',part.id, items)
    },[part])

    const onExpand = (id) =>{
        Select(id)
        onPage(id)   
    }

return (
    <div className="thumbnails-grid" key={`part-${part.id}`} >
        {             
    items.map(item => 
        <div align="center" valign="center" className="thumbnails-pane" key={`pane-${item.page}`}  
            //style={{backgroundImage:`url(${item.path})`}}
        >
            <img className="thumbnails-image" id={item.page} value={item.page} onClick={()=>Select(item.page)} alt={item.page}
            src={item.path} 
            style={{border: selected===item.page ?'2px solid gold':''}}
            />

            <div className={selected===item.page?"thumbnails-info thumbnails-select":"thumbnails-info"} 
                //style={{fontWeight:selected===item.page?'bold':'normal'}}
            >
                {item.page}
            </div>

                <Button className="mr-2" variant={"outline-info"}  
        style={{ position:'absolute', width: '6%', aspectRatio:'1 / 1', top:0, right:0, margin:'6px' }}
            onClick={()=>onExpand(item.page)}
            ><i className={"fa fa-expand"} style={{display: 'flex', justifyContent: 'center'}}></i>
            </Button>
                
        </div>   
    )         
        }  

    </div>   
)}


    const moshaf = {
        api: "https://moshaf.akademy.ma/api/",
        //api:"http://localhost:6239/api/",
        parts: 30,
        pages: 604,
        token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiaXNtaSI6Itio2ZDYs9uh2YXZkCDZsdmE2YTZh9mQINmx2YTYsdmR2Y7Yrduh2YXZjtmA2bDZhtmQINmx2YTYsdmR2Y7YrdmQ2YrZhdmQIiwiYWxhYW4iOiIxMC8xMi8yMDIyIiwiaWF0IjoxNjY1NTc2NDI5fQ.lYIP0dvSfiTaoJ1r42i168YbeHl2VCw-ToHLYQ7AuSI"
    }
    export {PartControl, Part, Parts}