import React, {useState, useEffect} from "react"
import {Modal,Button,Row} from 'react-bootstrap'
import './Sign.css'
import SignEmail from "./SignEmail"
import SignPhone from "./SignPhone"
import SignApi from './SignApi'
import {ToNotify} from '../Shared/ViewControl'

const signApi = new SignApi()

const SignModal = ({app, show, logo, onEtat, onHide}) => {
  const [message, Message] = useState('')
  const [activeTab, setActiveTab] = useState("Email")

  signApi.data.app = app

    const onEmail = () => {
        setActiveTab("Email")
    }

    const onPhone = () => {
      //Message("Phone ?")
      setActiveTab("Phone")
    }

    useEffect(() => {
      if(signApi.data.mode===3) {
        window.location.reload()
      } 
    }, [signApi.data.mode])

    const onClose = () => {
      onHide()
      if(signApi.data.mode===3) {
        window.location.reload()
      }      
    }

  return (
    <div className="mt-5 d-flex justify-content-left">

    <Modal show={show}
            onHide={onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                animation={false}
                centered
            >
          
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Sign In {app}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row style={{ justifyContent:"center", marginBottom:"1rem" }}>
            <img className="Logo" src={logo} alt="Home" style={{ width: '200px'}} />
          </Row>
    
    <div className="SignTabs">
      { signApi.data.mode===1 ?
      <ul className="nav">
        <li className={activeTab === "Email" ? "SignActive" : ""} onClick={onEmail}>Email</li>
        <li className={activeTab === "Phone" ? "SignActive" : ""} onClick={onPhone}>Phone</li>
      </ul>
      : 
      <h3 style={{color:"lightblue", margin:"2rem"}}>{signApi.message}</h3> 
      }
      
      { activeTab === "Email" ? <SignEmail signApi={signApi} onMessage={Message}/> 
                              : <SignPhone signApi={signApi} onMessage={Message}/> }
    </div>

    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-danger" onClick={onClose}><i className="fa fa-close"></i> Close</Button>
    </Modal.Footer>
  </Modal>
 
    <ToNotify 
      message={message}
      onClose={()=>{Message('')}}
      onEtat={onEtat}
    />    

    </div>

  )
}
export default SignModal