import React, { useState, useEffect} from 'react'
import './Reading.css'
import ReadControl from './ReadControl'
import {Parts} from './Parts'
import {Fetch} from '../Api'
import { Timers } from '../Timer/Timers'


const Reading = ({tab, page, onPage, onEtat, search}) => {
  
    const [part, Part] = useState({id:1, page:1, pages:21})
    const [note, Note] = useState(0)

    const data = {parts:30, pages:604, path:"https://moshaf.akademy.ma/safahaat/"}

    const [duration, Duration] = useState(0)
    const [reported, Reported] = useState(6)


    useEffect(() => {
        let number=6
        const timer = setTimeout(function() {
            //console.log("duration reported : ", duration, reported)   
            Duration(duration + 1)
            if(reported===duration)
            {
                Fetch(`timer`, {
                    ActivityId:1,
                    ElementId:page,
                    Focused:duration*number
                }, 1)
                Duration(0)

                let random=Math.floor(Math.random() * 10 + 1)
                Reported(random)
                
            }
        }, number*1000)
    
        return () => { // this should work flawlessly besides some milliseconds lost here and there 
            clearTimeout(timer)
        }            
    }, [duration]);
    
    const onPrev = () => {
        onClick(page>0 ? page-1 : data.pages)
    }

    const onNext =() => {
        onClick(page<data.pages ? page+1 : 0)
    }

    const onPart = (selected) => {
        //console.log('Reading.onPart', selected)
        if(selected.id>0 && selected.id<=data.parts){
                Part(selected)
        }
    }

    const onClick = (selected) => {
        //console.log('Reading.onPage', selected)   
        //alert(selected)
        if(selected>=0 && selected<=data.pages) {
            onPage(selected)
            //Tab("Read")
            onEtat(selected)
        }
    }

    const onNote = (id) => {
        Note(id)
    }

return (
    <div className="Tabs">
    
    { tab === "Part" ? 
        <Parts path={data.path} part={part} name='parts'  page={page} onPart={onPart} onPage={onPage} /> 
    : tab === "Read" ? 
        <ReadControl path={data.path} part={part} page={page} onNext={onNext} onPrev={onPrev} /> 
    : 
        <Timers element={page} onElement={onClick} reported={reported}/>
    }


    </div>
     )
}

export default Reading
